import Store from './tools/store';

export function isPhoneNumberLocal(phoneNo) {
  return Store.isUnitedStates()
    ? isPhoneNumberUS(phoneNo)
    : isPhoneNumberUK(phoneNo);
}

export function isPhoneNumberUK(phoneNo) {
  if (!isAString(phoneNo)) {
    return false;
  }

  phoneNo = phoneNo.replace(/\D/g, '');

  const blacklist = /^0800[0-9]{6}$/;

  if (blacklist.test(phoneNo)) {
    return false;
  }

  const regex = /[0-9]{9,20}/;
  return regex.test(phoneNo);
}

export function isPhoneNumberUS(phoneNo) {
  if (!isAString(phoneNo)) {
    return false;
  }

  phoneNo = phoneNo.replace(/[\W]/g, '');
  // https://www.quora.com/What-is-the-American-mobile-phone-number-format
  // +1 NXX-NXX-XXXX
  // N=digits 2-9, X=digits 0-9
  const mobileRegex = /(1?)[2-9][0-9]{2}[2-9][0-9]{2}[0-9]{2,4}/;

  // https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s02.html#validation-naphone-solution-js
  const landlineRegex = /(1?)[0-9]{3}[0-9A-Za-z]{3}[0-9A-Za-z]{0,4}/;

  return mobileRegex.test(phoneNo) || landlineRegex.test(phoneNo);
}

export function isPostalCodeLocal(postalCode) {
  if (Store.isUnitedStates()) {
    return isPostalCodeUS(postalCode);
  } else if (Store.isUnitedKingdom()) {
    return isPostalCodeUK(postalCode);
  } else if (Store.isIreland()) {
    return isPostalCodeIE(postalCode);
  } else {
    return false;
  }
}

export function isPostalCodeUK(postalCode) {
  if (!isAString(postalCode)) {
    return false;
  }

  postalCode = postalCode.replace(/\s/g, '');
  // https://en.wikipedia.org/w/index.php?title=Postcodes_in_the_United_Kingdom&oldid=831105336#Validation
  const regex = /^([Gg][Ii][Rr]0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))[0-9][A-Za-z]{2})$/;

  return regex.test(postalCode);
}

export function isPostalCodeIE(postalCode) {
  if (!isAString(postalCode)) {
    return false;
  }

  function isEircode() {
    postalCode = postalCode.replace(/\s/g, '');

    // https://www.citizensinformation.ie/en/consumer/phone-internet-tv-and-postal-services/eircode/
    const routing = postalCode.slice(0, 3);
    const addressIdentifier = postalCode.slice(3);
    const routingCorrect =
      routing == new RegExp('^D6W$', 'i').test(routing) ||
      new RegExp('^[ACDEFHKNPRTVWXY][ACDEFHKNPRTVWXY0-9]+$', 'i').test(routing);
    const addressIdentifierCorrect = new RegExp(
      '^[ACDEFHKNPRTVWXY0-9]{4}$',
      'i'
    ).test(addressIdentifier);

    return routingCorrect && addressIdentifierCorrect;
  }

  function isPostalDistrict() {
    const dublinDistrict = new RegExp(
      '^(d|dublin\\s?)([1-9]|1[0-9]|2[0-4])$',
      'gi'
    );
    const corkDistrict = new RegExp('^(c|cork\\s?)([1-4])$', 'gi');

    return dublinDistrict.test(postalCode) || corkDistrict.test(postalCode);
  }

  return isEircode() || isPostalDistrict();
}

const US_FIVE_DIGIT_ZIP_CODE = /^\d{5}$/;
export function isPostalCodeUS(postalCode) {
  if (!isAString(postalCode)) {
    return false;
  }

  return US_FIVE_DIGIT_ZIP_CODE.test(postalCode);
}

export function isEmailAddress(emailAddress) {
  if (!isAString(emailAddress)) {
    return false;
  }

  emailAddress = emailAddress.replace(/\s/g, '');
  const regex = /[a-zA-Z0-9\-_.+]+@[a-zA-Z0-9\-_.+]+\.[a-zA-Z0-9+]+/;

  return regex.test(emailAddress);
}

export function isAccountNumber(accountNumber, accountNumberRegex) {
  if (!isAString(accountNumber)) {
    return false;
  }

  accountNumber = accountNumber.replace(/\s/g, '');

  return accountNumberRegex.test(accountNumber);
}

function isAString(thing) {
  return typeof thing === 'string' || thing instanceof String;
}

export const validAddressLength = 30;

export function isValidAddressLength(addressField) {
  return addressField.length <= validAddressLength;
}

export function shouldStripPostalCodeWhitespace() {
  return Store.isUnitedKingdom() || Store.isUnitedStates();
}
